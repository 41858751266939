@mixin keyframes($animation: foo){
	@-webkit-keyframes #{$animation}{
		@content;
	}
	@-moz-keyframes #{$animation}{
		@content;
	}
	@-ms-keyframes #{$animation}{
		@content;
	}
	@-o-keyframes #{$animation}{
		@content;
	}
	@keyframes #{$animation}{
		@content;
	}
}