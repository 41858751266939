body{
	font-family: $pps-regular;
}
body,
html{
	width:100%;
	min-height:100vh;
}
img[style]{
    max-width: 100%;
    height: auto !important;
}
#app{
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.wrapper{
		flex-grow:1;
	}
}
button{
	outline: none;
}
textarea{
	resize: none;
}
figure{
	margin: 0;
}
h1,h2,h3,h4,h5,h6,p{
	margin: 0;
}
a{
    color: inherit;
}
a:focus,
a:hover{
    color: inherit;
    text-decoration: none;
}
*[data-animate]{
	opacity: 0;
}
.animated[data-animate]{
	opacity: 1;
}

.h1-padrao{
	color: $cinza;
	line-height: 1.2;

	span{
		font-family: $pps-bold;
	}

	@include media-breakpoint-up(sm) {
		font-size: 35px;
	}
	@include media-breakpoint-only(xs) {
		font-size: 26px;
	}
}
.desc-editor{
	font-size: 14px;
	line-height: 1.4;
	color: $cinza;

	a{
		&:hover{
			text-decoration: underline;
		}
	}
	iframe{
		max-width: 100%;
	}
	p + p{
		margin-top: 14px;
	}
}

.form-estilos{

	.form-group{
		margin-bottom: 20px;
	}

	input{
		height: 38px;
	}

	.form-control{
		background-color: #eee;
		font-size: 14px;
		color: #000;
		border: none;
		outline: none;
		border-radius: 19px;

		&::placeholder{
			color: #000;
		}
		&:focus{
			box-shadow: 0 0 0 0.2rem rgba(69, 175, 239, 0.3);
		}
	}
	.btn-padrao{
		margin: 20px auto 0 auto;
	}
}