.topo{

	.faixa-topo{
		padding: 12px 0;
		background-color: $azul;
		font-size: 13px;
		color: #fff;
		line-height: 1;

		.redes-topo{
			display: flex;
			align-items: center;
			justify-content: center;

			a + a{
				margin-left: 10px;
			}

			a{
				font-size: 16px;
				transition: all 0.3s linear;

				&:hover{
					color: $cinza;
				}
			}

			.txt{
				margin-right: 10px;
			}
		}

		@include media-breakpoint-up(sm) {
			.container{
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
		@include media-breakpoint-down(sm) {
			.redes-topo{
				.txt{
					display: none;
				}
			}
		}
		@include media-breakpoint-only(xs) {
			padding: 8px 0;
			.bem-vindo{
				text-align: center;
				margin-bottom: 5px;
			}
		} 
	}
	//.faixa-topo

	.content{
		background-color: #fff;
		padding: 8px 0;

		.whatsapp-topo{
			font-family: $mts-bold;
			color: $cinza;
			line-height: 1;
			text-align: right;

			.txt{
				font-size: 14px;
				margin-bottom: 5px;
			}
			a{
				font-size: 32px;
				transition: all 0.3s linear;

				&:hover{
					color: $azul;
				}
			}
		}

		@include media-breakpoint-up(md) {
			.container{
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
		@include media-breakpoint-down(sm) {
			.logo{
				margin-left: auto;
				margin-right: auto;
				text-align: center;
				width: 250px;
				max-width: 100%;
				height: auto;
			}
			.whatsapp-topo{
				display: none;
			}
		}
	}
}