.rodape{

	.txt{
		color: #000;
		font-size: 15px;
		line-height: 1;
		margin: 25px 0;
	}

	@include media-breakpoint-only(xl) {
		padding-top: 270px;
		background-position: center top;
		background-image: url('../images/bg-bottom-rodape.png');
		background-repeat: no-repeat;

		.segura-content{
			padding-left: 200px;
			color: #fff;
		}

		.autorais{
			margin-top: 95px;
			.container{
				display: flex;
				align-items: center;
				justify-content: center;

				a{
					margin-left: 10px;
				}
			}
		}
	}
	@include media-breakpoint-down(lg) {
		#scrollRodape{
			padding-top: 50px;
		}
		.autorais{
			background-color: $azul;
			text-align: center;
			margin-top: 30px;

			a{
				margin-top: 5px;
			}
		}
	}

	.segura-content{
		.midia{
			font-family: $mts-regular;
			font-size: 15px;

			a + a{
				margin-left: 15px;
			}

			i{
				min-width: 48px;
				width: 48px;
				height: 48px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 26px;
				border-radius: 50%;
			}
			.title{
				font-family: $pps-bold;
			}
		}
		@include media-breakpoint-only(xl) {
			.midia{
				i{
					background-color: #fff;
					color: $azul;
				}
			}
		}
		@include media-breakpoint-up(lg) {
			.midia + .midia{
				margin-top: 15px;
			}
			.midia{
				display: flex;
				align-items: center;
				i{
					margin-right: 10px;
				}
			}
		}
		@include media-breakpoint-down(lg) {
			.midia{
				color: #000;
				i{
					background-color: $azul;
					color: #fff;
				}
				.title{
					color: $azul;
				}
			}
		}
		@include media-breakpoint-only(lg) {
			padding-bottom: 90px;
		}
		@include media-breakpoint-only(md) {
			margin-top: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;

			.midia{
				width: 300px;
				max-width: 100%;
				text-align: center;

				i{
					margin: 0 auto 10px auto;
				}
			}	
		}
		@include media-breakpoint-down(sm) {
			margin-top: 40px;

			.midia + .midia{
				margin-top: 15px;
			}
			.midia{
				text-align: center;

				i{
					margin: 0 auto 10px auto;
				}
			}			
		}
	}	

	.autorais{
		font-size: 13px;
		color: #fff;
		font-family: $baloo;
		line-height: 1;
		padding: 10px 0;

		a{
			display: flex;
			align-items: center;
			justify-content: center;

			img{
				margin-left: 5px;
			}
		}
	}
}