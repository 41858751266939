.btn-expanded{
	padding: .375rem 1.5rem;

	&.btn-sm{
		padding: .25rem 1rem;
	}

	&.btn-lg{
		padding: .5rem 2rem;
	}
}

.btn-pill{
	border-radius: 1.5rem;

	&.btn-sm{
		border-radius: 1rem;
	}

	&.btn-lg{
		border-radius: 2rem;
	}
}

.btn-padrao{
	width: 270px;
	max-width: 100%;
	height: 70px;
	font-family: $mts-medium;
	font-size: 15px;
	line-height: 1;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 35px;
	outline: none;
	border: none;
	background-color: $cinza;
	position: relative;
	overflow: hidden;
	z-index: 1;

	&:visited{
		color: #fff;
	}

	&:before,
	&:after{
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 100%;
		left: 0;
		z-index: -1;
		transition: transform 0.3s linear;
		transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	}
	&:before{
		background-color: lighten($azul, 15%);
	}
	&:after{
		background-color: $azul;
	}

	&:hover{
		color: #fff;

		&:before,
		&:after{
			transform: translate3d(0, 100%, 0);
		}

		&:after{
			transition-delay: 0.175s;
		}
	}
}

#btn-whats{
	position: fixed;
	bottom: 15px;
	right: 15px;
	z-index: 997;
	background-color: #4CAF50;
	color: #fff;
	font-size: 26px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 0 16px rgba(0,0,0,0.5);

	&:after{
		content: '';
		position: absolute;
		top: calc(50% - 5px);
		left: calc(50% - 5px);
		width: 10px;
		height: 10px;
		background-color: rgba(255,255,255,0.5);
		border-radius: 50%;
		opacity: 0;
		animation: infiniteRipple 2s infinite;

	}
}

@include keyframes(infiniteRipple) {
	0%{
	    transform: scale(0);
	    opacity: 0.4; 
	}
  	100% {
	    transform: scale(8);
	    opacity: 0; 
	}
}