.banners{
	width: 1920px;
	max-width: 100%;
	overflow: hidden;
	margin-left: auto;
	margin-right: auto;
	position: relative;

	&:after{
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		background-image: url('../images/border-bottom-banner.png');
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: 100% 100%;
		z-index: 2;
	}

	.carousel-indicators{
		margin: 0;
		right: auto;
		left: 0;
		padding: 0;
		bottom: 20px;

		li{
			border: 0;
			width: 12px;
			height: 12px;
			opacity: 1;
			background-color: #fff;
			border-radius: 50%;
			transition: all 0.3s linear;

			&.active{
				background-color: $azul;
			}
		}
	}

	.control-down{
		display: inline-block;
		position: absolute;
		bottom: 5px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 3;
	}

	@include media-breakpoint-only(xl) {
		&:after{
			width: 332px;
			height: 70px;
		}
		.carousel-indicators{
			margin-left: calc(calc(100vw - 1170px)/2);
			margin-right: calc(calc(100vw - 1170px)/2);
		}
	}
	@include media-breakpoint-only(lg) {
		&:after{
			width: 250px;
			height: 53px;
		}
		.carousel-indicators{
			margin-left: calc(calc(100vw - 940px)/2);
			margin-right: calc(calc(100vw - 940px)/2);
		}
		.control-down{
			img{
				max-width: 50px;
			}
		}
	}
	@include media-breakpoint-only(md) {
		&:after{
			width: 180px;
			height: 38px;
		}
		.carousel-indicators{
			margin-left: calc(calc(100vw - 690px)/2);
			margin-right: calc(calc(100vw - 690px)/2);
		}
		.control-down{
			img{
				max-width: 40px;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		&:after{
			display: none;
		}
		.carousel-indicators{
			display: none;
		}
		.control-down{
			display: none;
		}
	}
}
//banners

.historia{
	padding-top: 50px;
	padding-bottom: 50px;

	.h1-padrao{
		margin-bottom: 30px;
	}
	.btn-padrao{
		margin-top: 30px;
	}

	@include media-breakpoint-only(xl) {
		.h1-padrao{
			display: inline-block;
			position: relative;
			padding-bottom: 10px;

			&:after{
				content: '';
				position: absolute;
				right: 0;
				bottom: 0;
				width: 370px;
				height: 4px;
				border-radius: 2px;
				background-color: $cinza;
			}
		}
	}
	@include media-breakpoint-down(md) {
		.lazyimage{
			margin: 30px auto 0 auto;
		}
		.btn-padrao{
			margin-left: auto;
			margin-right: auto;
		}
	}
}
//.historia

.card-produtos{
	margin-top: 50px;

	.img{
		.lazyimage{
			overflow: hidden;
			border-radius: 10px;
		}
	}

	.content{

		.nome{
			color: $azul;
			font-size: 16px;
			line-height: 1.2;
			font-family: $mts-black;
		}
		.destaque{
			font-size: 22px;
			color: $cinza;
			font-family: $pps-bold;
			line-height: 1.2;
		}
		.texto{
			font-size: 13px;
			line-height: 1.4;
			color: #000;

		}
		.botao{
			width: 166px;
			max-width: 100%;
			height: 42px;
			background-color: $cinza;
			color: #fff;
			font-size: 13px;
			line-height: 1;
			font-family: $mts-medium;
			border-radius: 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			overflow: hidden;
			position: relative;
			z-index: 1;

			&:after{
				content: '';
				position: absolute;
				height: 100%;
				width: 0;
				border-radius: 20px;
				background-color: $azul;
				z-index: -1;
				bottom: 0;
				right: 0;
				transition: all 0.3s ease-out;
			}

			&:hover{
				&:after{
					width: 100%;
				}
			}

			span{
				padding-left: 30px;
			}

			.icone{
				width: 42px;
				height: 42px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: $azul;
				border-radius: 50%;
				position: absolute;
				right: -1px;
				bottom: 0;
				z-index: 3;
			}
		}
	}

	@include media-breakpoint-only(xl) {
		.content{
			.texto{
				height: 54px;
				line-clamp: 3;
				-webkit-line-clamp: 3;
			}
		}
	}
	@include media-breakpoint-up(md) {
		.content{
			.nome,
			.destaque,
			.texto{
				overflow: hidden;
				max-height: 100%;
				display: -webkit-box;
				-webkit-box-orient: vertical;
			}
			.nome{
				height: 38px;
				line-clamp: 2;
				-webkit-line-clamp: 2;
			}
			.destaque{
				height: 52px;
				line-clamp: 2;
				-webkit-line-clamp: 2;
			}
		}
	}
	@include media-breakpoint-only(lg) {
		.content{
			.texto{
				height: 36px;
				line-clamp: 2;
				-webkit-line-clamp: 2;
			}
		}
	}
	@include media-breakpoint-only(md) {
		.content{
			.texto{
				height: 54px;
				line-clamp: 3;
				-webkit-line-clamp: 3;
			}
			.botao{
				span{
					padding-left: 20px;
				}
			}
		}
	}
	@include media-breakpoint-up(sm) {
		.content{
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}
	@include media-breakpoint-only(sm) {
		.content{
			.nome,
			.destaque{
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
			.texto{
				overflow: hidden;
				max-height: 100%;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				height: 36px;
				line-clamp: 2;
				-webkit-line-clamp: 2;
			}
		}
	}

	@include media-breakpoint-only(xs) {
		width: 255px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		text-align: center;

		.row{
			flex-direction: column;

			.img{
				order: 1;
				margin-bottom: 10px;

				.lazyimage{
					margin-left: auto;
					margin-right: auto;
				}
			}
			.content{
				order: 2;

				.destaque{
					margin-top: 10px;
					margin-bottom: 10px;
				}

				.botao{
					margin: 10px auto 0 auto;
				}
			}
		}
	}
}
//.card-produtos

.produtos{
	padding-bottom: 50px;

	.h1-padrao{
		text-align: center;
	}
}

.banner-central{
	@include media-breakpoint-only(xl) {
		margin-bottom: -100px;
	}
}