$imgs: '../images';
$fonts: '../fonts';

// MEDIA BREAKPOINTS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

// TAMANHOS DO CONTAINER
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 970px,
  xl: 1200px
) !default;

//CORES
$azul: #00AFEF;
$cinza: #373435;

//FONTS
$baloo: 'baloo_bhaijaanregular';
$mts-black: 'montserratblack';
$mts-bold: 'montserratbold';
$mts-medium: 'montserratmedium';
$mts-regular: 'montserratregular';
$pps-bold: 'poppinsbold';
$pps-regular: 'poppinsregular';