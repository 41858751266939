@font-face {
    font-family: 'baloo_bhaijaanregular';
    src: url('../fonts/baloobhaijaan-regular/baloobhaijaan-regular-webfont.eot');
    src: url('../fonts/baloobhaijaan-regular/baloobhaijaan-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/baloobhaijaan-regular/baloobhaijaan-regular-webfont.woff2') format('woff2'),
         url('../fonts/baloobhaijaan-regular/baloobhaijaan-regular-webfont.woff') format('woff'),
         url('../fonts/baloobhaijaan-regular/baloobhaijaan-regular-webfont.ttf') format('truetype'),
         url('../fonts/baloobhaijaan-regular/baloobhaijaan-regular-webfont.svg#baloo_bhaijaanregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratblack';
    src: url('../fonts/montserrat-black/montserrat-black-webfont.eot');
    src: url('../fonts/montserrat-black/montserrat-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-black/montserrat-black-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-black/montserrat-black-webfont.woff') format('woff'),
         url('../fonts/montserrat-black/montserrat-black-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-black/montserrat-black-webfont.svg#montserratblack') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratbold';
    src: url('../fonts/montserrat-bold/montserrat-bold-webfont.eot');
    src: url('../fonts/montserrat-bold/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-bold/montserrat-bold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-bold/montserrat-bold-webfont.woff') format('woff'),
         url('../fonts/montserrat-bold/montserrat-bold-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-bold/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratmedium';
    src: url('../fonts/montserrat-medium/montserrat-medium-webfont.eot');
    src: url('../fonts/montserrat-medium/montserrat-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-medium/montserrat-medium-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-medium/montserrat-medium-webfont.woff') format('woff'),
         url('../fonts/montserrat-medium/montserrat-medium-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-medium/montserrat-medium-webfont.svg#montserratmedium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratregular';
    src: url('../fonts/montserrat-regular/montserrat-regular-webfont.eot');
    src: url('../fonts/montserrat-regular/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-regular/montserrat-regular-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-regular/montserrat-regular-webfont.woff') format('woff'),
         url('../fonts/montserrat-regular/montserrat-regular-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-regular/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'poppinsbold';
    src: url('../fonts/poppins-bold/poppins-bold-webfont.eot');
    src: url('../fonts/poppins-bold/poppins-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/poppins-bold/poppins-bold-webfont.woff2') format('woff2'),
         url('../fonts/poppins-bold/poppins-bold-webfont.woff') format('woff'),
         url('../fonts/poppins-bold/poppins-bold-webfont.ttf') format('truetype'),
         url('../fonts/poppins-bold/poppins-bold-webfont.svg#poppinsbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'poppinsregular';
    src: url('../fonts/poppins-regular/poppins-regular-webfont.eot');
    src: url('../fonts/poppins-regular/poppins-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/poppins-regular/poppins-regular-webfont.woff2') format('woff2'),
         url('../fonts/poppins-regular/poppins-regular-webfont.woff') format('woff'),
         url('../fonts/poppins-regular/poppins-regular-webfont.ttf') format('truetype'),
         url('../fonts/poppins-regular/poppins-regular-webfont.svg#poppinsregular') format('svg');
    font-weight: normal;
    font-style: normal;
}